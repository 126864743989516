import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { PaymentsTile } from '../../components/reporting/paymentsPieChart/PaymentsTile';
import './Reports.scss';
import { firstDateOfCurrentMonth } from '../../utils/date';

const Reports = () => {
  const [startDate, setStartDate] = useState(dayjs(firstDateOfCurrentMonth()));

  return (
    <div className='report-page'>
      <div className='picker-options'>
        <div className='date-picker'>
          <span>Fecha Inicio</span>
          <DatePicker
            maxTagCount='responsive'
            size='small'
            picker='month'
            defaultValue={dayjs(startDate, 'MM/YY')}
            onChange={date => setStartDate(date)}
            value={startDate}
            allowClear={false}
          />
        </div>
        {
          /*
          <div className='date-picker'>
            <span>Fecha Fin</span>
            <DatePicker
              maxTagCount='responsive'
              size='small'
              picker='month'
              defaultValue={dayjs(endDate, 'MM/YY')}
              onChange={date => setEndDate(date)}
              value={endDate}
              allowClear={false}
            />
          </div>
          */
        }
      </div>
      <div className='tiles-container'>
        <PaymentsTile
          title='Medios de Pago - 15'
          startDate={startDate.format('YYYY-MM-DD')}
          endDate={dayjs(new Date(startDate.year(), startDate.month(), 15)).format('YYYY-MM-DD')}
          colorScheme='nivo'
        />
        <PaymentsTile
          title='Medios de Pago - 30'
          startDate={dayjs(new Date(startDate.year(), startDate.month(), 16)).format('YYYY-MM-DD')}
          endDate={startDate.add(1, 'month').add(-1, 'day').format('YYYY-MM-DD')}
          colorScheme='nivo'
        />
      </div>
    </div>
  );
};

export default Reports;