import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../utils/requests';
import { Form, Layout, Tooltip } from 'antd';
import Loading from '../../../components/loading/Loading';
import { Content, Header } from 'antd/es/layout/layout';
import { QuestionCircleOutlined } from '@ant-design/icons';
import TableComponent from '../../../components/table/TableComponent';
import ClientsModal from './modal/ClientsModal';
import './Clients.scss';

const HEADER_FORMATTERS = {
	firstName: 'Nombres',
	lastName: 'Apellidos',
	personalId: 'Cédula',
	cellphone: 'Contacto',
};

const DATA_FORMATTERS = {};

const getClients = (setClients, setError, setIsLoading) => {
	fetcher(
		'clients',
		{ method: 'GET' },
		response => {
			setClients(response);
			setIsLoading(false);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);
};

const getTableData = (setSchema, setClients, setIsLoading, setError) => {
	fetcher(
		'clients/schema',
		{ method: 'GET' },
		response => {
			setSchema(response.filter(c => c !== 'planSpeed'));
			getClients(setClients, setError, setIsLoading);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);
};

const Clients = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [columns, setColumns] = useState([]);
	const [clients, setClients] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});
	const [form] = Form.useForm();

	useEffect(() => {
		setIsLoading(true);
		getTableData(setColumns, setClients, setIsLoading, error => console.log(error));
	}, []);

	const onRowClicked = plan => {
		setOpenModal(true);
		setSelectedRow(plan);
	};

	const handleModalCancel = () => {
		setOpenModal(false);
		setSelectedRow({});
	};

	const handleModalOk = values => {
		setOpenModal(false);
		setIsLoading(true);
		if (values.id) {
			fetcher(
				'clients',
				{ method: 'PUT', body: values },
				() => {
					setClients(clients.map(p => (p.id === values.id ? { ...values } : p)));
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		} else {
			fetcher(
				'clients',
				{ method: 'POST', body: values },
				({ id }) => {
					setClients([...clients, { ...values, id }]);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		}
		setSelectedRow({});
	};

	const handleDelete = id => {
		setOpenModal(false);
		setIsLoading(true);
		fetcher(
			'clients',
			{ method: 'DELETE', body: { id } },
			() => {
				setClients(clients.filter(p => p.id !== id));
				setIsLoading(false);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
		setSelectedRow({});
	};

	return (
		<>
			{isLoading && <Loading />}
			<Layout className='clients'>
				<Header className='clients-header'>
					<span className='title'>
						Clientes
						<Tooltip title='Podrás modificar información básica del cliente. No se crean clientes por este medio, para eso debes hacer una nueva instalación.'>
							<QuestionCircleOutlined style={{ color: 'blue', fontSize: 14 }} />
						</Tooltip>
					</span>
				</Header>
				<Content className='clients-content'>
					<TableComponent
						headerFormatters={HEADER_FORMATTERS}
						dataFormatters={DATA_FORMATTERS}
						data={clients}
						columns={columns}
						onClick={onRowClicked}
					/>
					<ClientsModal
						form={form}
						open={openModal}
						onCancel={handleModalCancel}
						onOk={handleModalOk}
						onDelete={handleDelete}
						values={selectedRow}
					/>
				</Content>
			</Layout>
		</>
	);
};

export default Clients;
