import React, { useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { fetcher } from '../../../utils/requests';
import { getUserData } from '../../../utils/auth';
import Loading from '../../../components/loading/Loading';
import { passwordComplianceValidator } from '../../../utils/formatters';

const FORM_DEFAULT = {
	oldPassword: '',
	newPassword: '',
	newPasswordValidate: '',
};

const passwordValidator = ({ getFieldValue }) => ({
	validator: (_, value) => {
		if (!value || getFieldValue('newPassword') === value) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('Las contraseñas no coinciden'));
	},
});

const PasswordFormModal = ({ form, open, onCancel, onAccept }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { id } = getUserData();

	const onChangePassword = async () => {
		try {
			const validation = await form.validateFields();
			if (!validation?.errorFields?.length) {
				const values = form.getFieldsValue();
				setIsLoading(true);
				fetcher(
					'users',
					{
						method: 'PUT',
						body: {
							id,
							oldPassword: values.oldPassword,
							password: values.newPassword,
						},
					},
					response => {
						setIsLoading(false);
						if (!response.id) {
							form.setFields([
								{
									name: 'oldPassword',
									errors: ['Esta no es tu contraseña! 🤨'],
								},
							]);
						} else {
							form.resetFields();
							onAccept();
						}
					},
					error => {
						setIsLoading(false);
						console.log(error);
					},
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleCancel = () => {
		form.resetFields();
		onCancel();
	};
	return (
		<Modal
			title='Cambiar Contraseña'
			open={open}
			onOk={onChangePassword}
			onCancel={handleCancel}
		>
			{isLoading && <Loading />}
			<Form
				className='password-form'
				form={form}
				name='password_form'
				layout='vertical'
				initialValues={FORM_DEFAULT}
			>
				<Form.Item
					name='oldPassword'
					rules={[{ required: true, message: 'Contraseña anterior es obligatorio' }]}
				>
					<Input.Password placeholder='Contraseña anterior' prefix={<LockOutlined />} />
				</Form.Item>

				<Form.Item
					name='newPassword'
					rules={[
						{ required: true, message: 'Nueva contraseña es obligatorio' },
						passwordComplianceValidator,
					]}
				>
					<Input.Password placeholder='Nueva contraseña' prefix={<LockOutlined />} />
				</Form.Item>

				<Form.Item
					name='newPasswordValidate'
					rules={[
						{ required: true, message: 'Nueva contraseña es obligatorio' },
						passwordValidator,
					]}
				>
					<Input.Password
						placeholder='Repite la nueva contraseña'
						prefix={<LockOutlined />}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default PasswordFormModal;
