import { Form, Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { fetcher, logout } from '../../utils/requests';
import { getUserData } from '../../utils/auth';
import Loading from '../../components/loading/Loading';
import PasswordFormModal from './passwordForm/PasswordForm';
import ProfileForm from './profileForm/ProfileForm';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'antd/es';
import { WarningOutlined } from '@ant-design/icons/lib';
import './Profile.scss';

const Profile = () => {
	const { id, username } = getUserData();
	const [userData, setUserData] = useState({});
	const [showPasswordChange, setShowPasswordChange] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		fetcher(
			'users',
			{ method: 'GET', params: { id } },
			response => {
				setUserData(response[0]);
				setIsLoading(false);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
	}, [id, username]);

	const profileChange = async (form, values) => {
		try {
			const validation = await form.validateFields();
			const profileValues = Object.keys(values)
				.filter(v => v !== 'password' && v !== 'personalId')
				.reduce(
					(acc, curr) => ({
						...acc,
						[curr]: values[curr],
					}),
					{},
				);
			if (!validation?.errorFields?.length) {
				setIsLoading(true);
				fetcher(
					'users',
					{
						method: 'PUT',
						body: {
							id,
							...profileValues,
						},
					},
					response => {
						logout(
							() => setIsLoading(false),
							error => {
								console.log(error);
								setIsLoading(false);
							},
							navigate,
						);
					},
					error => {
						setIsLoading(false);
					},
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			{isLoading && <Loading />}
			<Layout className='profile'>
				<Header className='profile-header'>
					<span className='title'>Perfil</span>
					<span className='subtitle'>
						<Tag className='tag' color='yellow-inverse' icon={<WarningOutlined />}>
							Importante
						</Tag>
						<span className='message'>
							Si guardas modificaciones, deberás volver a iniciar sesión
						</span>
					</span>
				</Header>
				<Content className='profile-content'>
					{!isLoading && (
						<ProfileForm
							form={form}
							submitText='Guardar'
							onFinish={profileChange}
							onCancel={() => {}}
							cancelText='Cancelar'
							values={userData}
							onPasswordChange={() => setShowPasswordChange(true)}
						/>
					)}
					<PasswordFormModal
						form={form}
						open={showPasswordChange}
						onCancel={() => setShowPasswordChange(false)}
						onAccept={() => {
							setShowPasswordChange(false);
							navigate('/login');
						}}
					/>
				</Content>
			</Layout>
		</>
	);
};

export default Profile;
