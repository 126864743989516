import { Card } from 'antd';
import React from 'react';
import './TileContainer.scss';

const TileContainer = ({ title, loading, children, width, height }) => {
    return (
        <Card className='tile-container' title={title} loading={loading} style={{ width, height }}>
            {children}
        </Card>
    );
};

export default TileContainer;