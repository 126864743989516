import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './components/login/Login';
import SideBar from './components/sidebar/SideBar';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import NewInstallation from './pages/newInstallation/NewInstallation';
import Header from './components/header/Header';
import Content from './components/content/Content';
import Connections from './pages/connections/Connections';
import Payments from './pages/payments/Payments';
import History from './pages/history/History';
import Profile from './pages/profile/Profile';
import Plans from './pages/configuration/plans/Plans';
import Providers from './pages/configuration/providers/Providers';
import Users from './pages/configuration/users/Users';
import AccessManager from './pages/configuration/accessManager/AccessManager';
import { Layout } from 'antd';
import { getUserData } from './utils/auth';
import Clients from './pages/configuration/clients/Clients';
import Reports from './pages/reports/Reports';
import './App.scss';

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<Layout className='home-layout'>
					<SideBar user={getUserData()} />
					<Layout className='navigation-layout'>
						<Header />
						<Content />
					</Layout>
				</Layout>
			</ProtectedRoute>
		),
		children: [
			{
				path: '/reports',
				element: <Reports />,
			},
			{
				path: '/newInstallation',
				element: <NewInstallation />,
			},
			{
				path: '/connections',
				element: <Connections />,
			},
			{
				path: '/payments',
				element: <Payments />,
			},
			{
				path: '/history',
				element: <History />,
			},
			{
				path: '/profile',
				element: <Profile />,
			},
			{
				path: '/plans',
				element: <Plans />,
			},
			{
				path: '/providers',
				element: <Providers />,
			},
			{
				path: '/users',
				element: <Users />,
			},
			{
				path: '/roles',
				element: <AccessManager />,
			},
			{
				path: '/clients',
				element: <Clients />,
			},
		],
	},
	{
		path: '/login',
		element: <Login />,
	},
]);

const App = () => {
	return (
		<div className='app'>
			<RouterProvider router={router} />
		</div>
	);
};

export default App;
