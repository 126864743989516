import React, { useEffect } from 'react';
import {
	ApartmentOutlined,
	DeleteOutlined,
	IdcardOutlined,
	LockOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Form, Input, Modal, Select } from 'antd';
import { passwordComplianceValidator } from '../../../../utils/formatters';
import ModalButtons from '../../../../components/buttons/ModalButtons';

const { Option } = Select;

const DEFAULT_VALUES = {
	username: null,
	personalId: null,
	password: null,
	role: null,
};

const UsersModal = ({ form, values, open, onOk, onCancel, onDelete, dependencies }) => {

	useEffect(() => {
		if (values.id) {
			form.setFieldsValue(values);
		} else {
			form.setFieldsValue(DEFAULT_VALUES);
		}
	}, [form, values]);

	const handleDelete = () => {
		form.resetFields();
		form.setFieldsValue({});
		onDelete(values.id);
	};

	return (
		<>
			<Modal
				title={values.id ? 'Modificar usuario' : 'Crear usuario'}
				open={open}
				onCancel={onCancel}
				footer={
					<ModalButtons
						form={form}
						values={values}
						onOk={() => {
							const formValues = form.getFieldsValue();
							onOk({
								...formValues,
								id: values.id,
							});
						}}
						onDelete={() =>
							Modal.confirm({
								title: 'Estas seguro que deseas eliminar este proveedor?',
								cancelText: 'Cancelar',
								okText: 'Eliminar',
								okType: 'danger',
								okButtonProps: { icon: <DeleteOutlined /> },
								onOk: handleDelete,
							})
						}
					/>
				}
			>
				<Form className='users-form' form={form} name='users_form' layout='vertical'>
					<Form.Item
						name='username'
						label={values.username ? 'Nombre completo' : ''}
						rules={[{ required: true, message: 'Nombre es obligatorio' }]}
					>
						<Input placeholder='Nombre completo' prefix={<UserOutlined />} />
					</Form.Item>

					<Form.Item
						name='personalId'
						label={values.personalId ? 'Cédula' : ''}
						rules={[{ required: true, message: 'Cédula es olbigatorio' }]}
					>
						<Input placeholder='Cédula' prefix={<IdcardOutlined />} />
					</Form.Item>
					{!values.id && (
						<Form.Item
							name='password'
							label={values.password ? 'Contraseña' : ''}
							rules={[
								{ required: true, message: 'El valor del plan es obligatorio' },
								passwordComplianceValidator,
							]}
						>
							<Input.Password
								placeholder='Contraseña'
								prefix={<LockOutlined />}
								visibilityToggle={false}
							/>
						</Form.Item>
					)}

					<Form.Item
						name='role'
						label={values.role ? 'Rol del usuario' : ''}
						rules={[{ required: true, message: 'El rol del usuario es obligatorio' }]}
					>
						<Select
							placeholder='Rol del usuario'
							suffixIcon={
								<ApartmentOutlined style={{ color: 'black', fontSize: 14 }} />
							}
						>
							{dependencies['role'].map(d => (
								<Option key={d.id} value={d.id}>
									{d.roleName}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default UsersModal;
