import React, { useEffect, useState } from 'react';
import { Menu, Layout } from 'antd';
import {
	PlusOutlined,
	DollarOutlined,
	HistoryOutlined,
	SettingOutlined,
	ContactsOutlined,
	WifiOutlined,
	UserOutlined,
	LockOutlined,
	ApiOutlined,
	TeamOutlined,
	BarChartOutlined,
} from '@ant-design/icons';
import InterglobalLogo from '../../assets/interglobal-logo.png';
import { useNavigate } from 'react-router-dom';
import './SideBar.scss';
import { fetcher } from '../../utils/requests';
import Loading from '../loading/Loading';
import { getUserData } from '../../utils/auth';

const getItem = (label, key, icon, children, type) => ({
	key,
	icon,
	children,
	label,
	type,
});

const items = [
	getItem('Reportes', 'reports', <BarChartOutlined />),
	getItem('Nueva instalación', 'newInstallation', <PlusOutlined />),
	getItem('Conexiones', 'connections', <ApiOutlined />),
	getItem('Pagos', 'payments', <DollarOutlined />),
	getItem('Histórico', 'history', <HistoryOutlined />),
	getItem('Configuración', 'configuration', <SettingOutlined />, [
		getItem('Proveedores', 'providers', <ContactsOutlined />),
		getItem('Planes', 'plans', <WifiOutlined />),
		getItem('Usuarios', 'users', <UserOutlined />),
		getItem('Roles', 'roles', <LockOutlined />),
		getItem('Clientes', 'clients', <TeamOutlined />),
	]),
];

const filterMenuItemsByRole = (items, allowed) =>
	items.reduce(
		(acc, curr) => [
			...acc,
			...(curr.children
				? [
					...(filterMenuItemsByRole(curr.children, allowed).length
						? [
							{
								...curr,
								children: filterMenuItemsByRole(curr.children, allowed),
							},
						]
						: []),
				]
				: [...(allowed.includes(`/${curr.key}`) ? [curr] : [])]),
		],
		[],
	);

const SideBar = () => {
	const [collapsed, setCollapsed] = useState(true);
	const [touchStart, setTouchStart] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [menuItems, setMenuItems] = useState([]);
	const [triggerNewRequest, setTriggerNewRequest] = useState(false);
	const { role } = getUserData();

	useEffect(() => {
		setIsLoading(true);
		fetcher(
			'roles/roleRoutes',
			{ method: 'GET', params: { roleId: role } },
			response => {
				setMenuItems(
					filterMenuItemsByRole(
						items,
						response.map(r => r.applicationRoute),
					),
				);
				setIsLoading(false);
			},
			error => {
				console.log(error);
				if (error.status === 302) {
					setTriggerNewRequest(!triggerNewRequest);
				} else {
					setMenuItems([]);
				}
				setIsLoading(false);
			},
		);
	}, [role, triggerNewRequest]);

	const navigate = useNavigate();

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const onClick = e => !e.children && navigate(e.key);

	const handleTouchStart = event => {
		const {
			touches: [{ clientX }],
		} = event;
		setTouchStart(clientX);
	};

	const handleTouchEnd = event => {
		const {
			changedTouches: [{ clientX: touchEnd }],
		} = event;

		if (touchStart > touchEnd) {
			setCollapsed(true);
		} else if (touchStart < touchEnd) {
			setCollapsed(false);
		}
		setTouchStart(0);
	};

	const { Sider } = Layout;

	return (
		<>
			{isLoading && <Loading />}
			<Sider
				id='side-bar'
				collapsible
				collapsed={collapsed}
				onCollapse={toggleCollapsed}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleTouchEnd}
				collapsedWidth={40}
			>
				<div className='logo-container'>
					{!collapsed && (
						<img
							className='sidebar-logo'
							src={InterglobalLogo}
							alt='interglobal-logo'
						/>
					)}
				</div>

				<Menu onClick={onClick} mode='inline' items={menuItems} forceSubMenuRender />
			</Sider>
		</>
	);
};

export default SideBar;
