import React from 'react';
import TileContainer from '../tileContainer/TileContainer';
import { ResponsivePie } from '@nivo/pie';
import './PaymentsPieChart.scss';

const PaymentsPieChart = ({ title, data, isLoading }) => (
    <TileContainer title={title} loading={isLoading} width={360} height={360}>
        {!isLoading && data.length
            ? <ResponsivePie
                data={data}
                margin={{ top: 40, right: 80, bottom: 60, left: 80 }}
                startAngle={-90}
                padAngle={2}
                cornerRadius={5}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                colors={{ datum: 'data.color' }}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={1}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsStraightLength={10}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={1}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: 'black',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
            : <div>No hay pagos para la fecha seleccionada</div>
        }
    </TileContainer>
);

export default PaymentsPieChart;;