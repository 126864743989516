const currencyFormatter = new Intl.NumberFormat('es-co');
export const formatCurrency = value => value ? `$${currencyFormatter.format(value)}` : '';
export const currencyParser = value => value ? value.replaceAll('.', '').replaceAll('$', '') : '';
export const hidePassword = password =>
    password
        .split('')
        .map(c => '*')
        .join('')
        .toString()
        .slice(0, 10);

export const passwordComplianceValidator = () => ({
    validator: (_, value) => {
        const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (!value || regularExpression.test(value)) {
            return Promise.resolve();
        }
        if (value.length < 6) {
            return Promise.reject(new Error('Tu contraseña debe tener más de 6 letas'));
        }
        return Promise.reject(
            new Error('Debes escoger una contraseña con numeros y caracteres especiales'),
        );
    },
});