import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../utils/requests';
import { Button, Form, Layout, Tooltip } from 'antd';
import Loading from '../../../components/loading/Loading';
import { Content, Header } from 'antd/es/layout/layout';
import { PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import UsersModal from './modal/UsersModal';
import TableComponent from '../../../components/table/TableComponent';
import { hidePassword } from '../../../utils/formatters';
import './Users.scss';

const ENDPOINT = 'users';
const HEADER_FORMATTERS = {
	personalId: () => 'Cédula',
	username: () => 'Usuario',
	password: () => 'Contraseña',
	role: () => 'Rol',
};

const DATA_FORMATTERS = {
	password: v => hidePassword(v),
	role: (v, roles) => roles.find(r => r.id === v)?.roleName,
};

const getRoles = (setRoles, setIsLoading, setError) =>
	fetcher(
		'roles',
		{ method: 'GET' },
		response => {
			setRoles(response);
			setIsLoading(false);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);

const getUsers = (setUsers, setIsLoading, setError) =>
	fetcher(
		ENDPOINT,
		{ method: 'GET' },
		response => {
			setUsers(response);
		},
		error => {
			console.log(error);
			setError(error);
			setIsLoading(false);
		},
	);
const getData = (setSchema, setUsers, setRoles, setIsLoading, setError) => {
	setIsLoading(true);
	fetcher(
		`${ENDPOINT}/schema`,
		{ method: 'GET' },
		response => {
			setSchema(response);
			getUsers(
				r => {
					setUsers(r);
					getRoles(setRoles, setIsLoading, setError);
				},
				setIsLoading,
				setError,
			);
		},
		error => {
			setIsLoading(false);
			setError(error);
			console.log(error);
		},
	);
};

const Users = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [columns, setColumns] = useState([]);
	const [users, setUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});
	const [form] = Form.useForm();

	useEffect(() => {
		getData(setColumns, setUsers, setRoles, setIsLoading, error => console.log(error));
	}, []);

	const onRowClicked = row => {
		setOpenModal(true);
		setSelectedRow(row);
	};

	const handleModalCancel = () => {
		setOpenModal(false);
		setSelectedRow({});
	};

	const handleModalOk = values => {
		setOpenModal(false);
		setIsLoading(true);
		if (values.id) {
			fetcher(
				ENDPOINT,
				{
					method: 'PUT',
					body: Object.keys(values).reduce(
						(acc, key) => ({
							...acc,
							...(key === 'password' ? {} : { [key]: values[key] }),
						}),
						{},
					),
				},
				() => {
					console.log(users);
					setUsers(
						users.map(p => (p.id === values.id ? { ...selectedRow, ...values } : p)),
					);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		} else {
			fetcher(
				ENDPOINT,
				{ method: 'POST', body: values },
				({ id }) => {
					setUsers([...users, { ...values, id }]);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		}
		setSelectedRow({});
	};

	const handleDelete = id => {
		setOpenModal(false);
		setIsLoading(true);
		fetcher(
			ENDPOINT,
			{ method: 'DELETE', body: { id } },
			response => {
				setUsers(users.filter(p => p.id !== id));
				setIsLoading(false);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
		setSelectedRow({});
	};

	return (
		<>
			{isLoading && <Loading />}
			<Layout className='users'>
				<Header className='users-header'>
					<span className='title'>
						Usuarios
						<Tooltip title='Admninistrar usuarios de la aplicación'>
							<QuestionCircleOutlined style={{ color: 'blue', fontSize: 14 }} />
						</Tooltip>
					</span>
				</Header>
				<Content className='users-content'>
					<Button
						className='create-button'
						type='primary'
						icon={<PlusCircleOutlined />}
						onClick={() => {
							setOpenModal(true);
							setSelectedRow({});
						}}
					>
						Nuevo Usuario
					</Button>

					<TableComponent
						headerFormatters={HEADER_FORMATTERS}
						dataFormatters={DATA_FORMATTERS}
						dependencies={{ role: roles }}
						columns={columns}
						data={users}
						onClick={onRowClicked}
					/>

					<UsersModal
						form={form}
						open={openModal}
						onCancel={handleModalCancel}
						onOk={handleModalOk}
						onDelete={handleDelete}
						values={selectedRow}
						dependencies={{ role: roles }}
					/>
				</Content>
			</Layout>
		</>
	);
};

export default Users;
