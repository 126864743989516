import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './Loading.scss';

const Loading = () => {
	return (
		<div className='loading-container'>
			<LoadingOutlined className='loading'/>
		</div>
	);
};

export default Loading;
