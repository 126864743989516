import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../utils/requests';
import PaymentsPieChart from './PaymentsPieChart';

const PAYMENT_TYPES = {
    null: 'Sin Pagar',
    'bankTransfer': 'Transferencia',
    'cash': 'Efectivo'
};

const colors = {
    [PAYMENT_TYPES[null]]: '#f47560',
    [PAYMENT_TYPES['bankTransfer']]: 'rgb(51, 160, 44)',
    [PAYMENT_TYPES['cash']]: '#97e3d5',
};

const getPayments = (startDate, endDate, setPayments, setIsLoading, setError) => fetcher(
    'reporting/payments',
    {
        method: 'POST',
        body: {
            metrics: ["payedValue"],
            groupBys: ["paymentType"],
            startDate,
            endDate,
            countQuery: true
        }
    },
    payments => {
        setPayments(payments.map(p => p.paymentType ? p : { ...p, paymentType: PAYMENT_TYPES[null] }));
        setIsLoading(false);
    },
    error => {
        setIsLoading(false);
        setError(error);
    });

export const PaymentsTile = ({ title, startDate, endDate }) => {
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getPayments(startDate, endDate, setPayments, setIsLoading, error => console.log(error));
    }, [startDate, endDate]);

    return (
        <PaymentsPieChart
            title={title}
            data={payments.map(p => ({
                id: p.paymentType,
                label: p.paymentType,
                value: p.payedValueCount,
                color: colors[p.paymentType]
            }))}
            isLoading={isLoading}
        />
    );
};
