import { SearchOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useCallback, useState } from 'react';
import './TableComponent.scss';

const ORDER_COLUMN = 'order';

const DEFAULT_FORMATTERS = {
	[ORDER_COLUMN]: () => '#',
};

const TableComponent = ({
	data,
	columns,
	headerFormatters,
	dataFormatters,
	onClick,
	dependencies,
	searchFields,
}) => {
	const [height, setHeight] = useState(null);
	const tableParent = useCallback(node => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);
	const dataSource =
		data && data.length
			? data.map((d, index) => ({
					order: index + 1,
					...columns.reduce(
						(acc, key) => ({
							...acc,
							[key]:
								dataFormatters && dataFormatters[key]
									? dependencies && dependencies[key]
										? dataFormatters[key](d[key], dependencies[key])
										: dataFormatters[key](d[key])
									: d[key],
						}),
						{},
					),
			  }))
			: [];
	const headerFormatterWithDefaults = { ...DEFAULT_FORMATTERS, ...headerFormatters };
	const tableColumns =
		data && data.length
			? [
					{
						title: headerFormatterWithDefaults[ORDER_COLUMN]
							? headerFormatterWithDefaults[ORDER_COLUMN]
							: ORDER_COLUMN,
						dataIndex: ORDER_COLUMN,
						key: ORDER_COLUMN,
						minWidth: 50,
					},
					...columns?.map(column =>
						typeof column === 'object'
							? column
							: {
									title: headerFormatterWithDefaults[column]
										? headerFormatterWithDefaults[column]
										: column,
									dataIndex: column,
									key: column,
									minWidth: 100,
									hidden: column === 'id',
									...(searchFields?.includes(column)
										? {
												filters: dataSource.reduce(
													(acc, d) => [
														...acc,
														...(acc.find(v => v.value === d[column])
															? []
															: [
																	{
																		text: d[column],
																		value: d[column],
																	},
															  ]),
													],
													[],
												),
												filterSearch: true,
												onFilter: (value, record) =>
													record[column].includes(value),
												filterMode: 'menu',
												filterIcon: (
													<SearchOutlined style={{ fontSize: '16px' }} />
												),
										  }
										: {}),
							  },
					),
			  ]
			: [];
	return (
		<div className='table-container' ref={tableParent}>
			<Table
				scroll={{ x: 'max-content', y: height }}
				columns={tableColumns}
				dataSource={dataSource}
				tableLayout='auto'
				pagination={{
					position: ['bottomCenter'],
					responsive: true,
					pageSizeOptions: ['5', '10', '25', '50'],
					alignCenter: 'center',
				}}
				onRow={record => ({
					onClick: () => (onClick ? onClick(data.find(p => p.id === record.id)) : null),
				})}
			/>
		</div>
	);
};

export default TableComponent;
