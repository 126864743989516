import React from 'react';
import { Button, Form, Input } from 'antd';
import { BoxPlotOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';

const RouterForm = ({ form, onFinish, submitText, onCancel, cancelText, values }) => (
	<Form
		form={form}
		name='router_form'
		className='router-form'
		initialValues={values}
		onFinish={value => onFinish(form, value)}
		layout='vertical'
	>
		<Form.Item
			name='address'
			rules={[{ required: true, message: 'Dirección IP es obligatorio' }]}
		>
			<Input prefix={<UserOutlined />} placeholder='Dirección IP' />
		</Form.Item>

		<Form.Item name='wifiName' rules={[{ required: true, message: 'Wifi es obligatorio' }]}>
			<Input prefix={<UserOutlined />} placeholder='Nombre del Wifi' />
		</Form.Item>

		<Form.Item
			name='wifiPassword'
			rules={[{ required: true, message: 'Contraseña del Wifi es obligatorio' }]}
		>
			<Input.Password
				prefix={<LockOutlined />}
				placeholder='Contraseña del Wifi'
				autoComplete='new-password'
			/>
		</Form.Item>

		<Form.Item
			name='napBoxConnector'
			rules={[{ required: true, message: 'Conector de Caja NAP es obligatorio' }]}
		>
			<Input prefix={<BoxPlotOutlined />} placeholder='Conector Caja NAP' />
		</Form.Item>

		<Form.Item name='comments'>
			<Input.TextArea placeholder='Comentarios' />
		</Form.Item>

		<Form.Item className='form-controls'>
			<Button type='primary' htmlType='submit'>
				{submitText}
			</Button>
			{onCancel && (
				<Button type='text' onClick={() => onCancel(form.getFieldsValue())}>
					{cancelText}
				</Button>
			)}
		</Form.Item>
	</Form>
);

export default RouterForm;
