import { Button, Form, Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import Loading from '../../../components/loading/Loading';
import { fetcher } from '../../../utils/requests';
import { PlusCircleOutlined } from '@ant-design/icons';
import AccessManagerModal from './modal/AccessManagerModal';
import TableComponent from '../../../components/table/TableComponent';
import './AccessManager.scss';

const ROLES_ENDPOINT = 'roles';
const HEADER_FORMATTERS = {
	id: 'Id',
	roleName: 'Rol',
	description: 'Descripción',
};
const DATA_FORMATTERS = {};

const getRoles = (setRoles, setIsLoading, setError) => {
	fetcher(
		ROLES_ENDPOINT,
		{ method: 'GET' },
		response => {
			setRoles(response);
			setIsLoading(false);
		},
		error => {
			setIsLoading(false);
			setError(error);
		},
	);
};

const getData = (setSchema, setRoles, setIsLoading, setError) => {
	setIsLoading(true);
	fetcher(
		`${ROLES_ENDPOINT}/schema`,
		{ method: 'GET' },
		response => {
			setSchema(response);
			getRoles(setRoles, setIsLoading, setError);
		},
		error => {
			setIsLoading(false);
			setError(error);
		},
	);
};

const AccessManager = () => {
	const [roles, setRoles] = useState([]);
	const [columns, setSchema] = useState([]);
	const [selectedRow, setSelectedRow] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		getData(setSchema, setRoles, setIsLoading, error => console.log(error));
	}, []);
	const onRowClicked = row => {
		setOpenModal(true);
		setSelectedRow({ ...row, access: [] });
	};

	const handleModalCancel = () => {
		setOpenModal(false);
		setSelectedRow({});
	};

	const handleModalOk = values => {
		setOpenModal(false);
		setIsLoading(true);
		if (values.id) {
			fetcher(
				ROLES_ENDPOINT,
				{
					method: 'PUT',
					body: values,
				},
				() => {
					setRoles(
						roles.map(p => (p.id === values.id ? { ...selectedRow, ...values } : p)),
					);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		} else {
			fetcher(
				ROLES_ENDPOINT,
				{ method: 'POST', body: values },
				({ id }) => {
					setRoles([...roles, { ...values, id }]);
					setIsLoading(false);
				},
				error => {
					console.log(error);
					setIsLoading(false);
				},
			);
		}
		setIsLoading(false);
		setSelectedRow({});
	};

	const handleDelete = id => {
		setOpenModal(false);
		setIsLoading(true);
		fetcher(
			ROLES_ENDPOINT,
			{ method: 'DELETE', body: { id } },
			() => {
				setRoles(roles.filter(p => p.id !== id));
				setIsLoading(false);
			},
			error => {
				console.log(error);
				setIsLoading(false);
			},
		);
		setIsLoading(false);
		setSelectedRow({});
	};

	return (
		<>
			{isLoading && <Loading />}
			<Layout className='access-manager'>
				<Header className='access-manager-header'>
					<span className='title'>Roles</span>
				</Header>
				<Content className='access-manager-content'>
					<Button
						className='create-button'
						type='primary'
						icon={<PlusCircleOutlined />}
						onClick={() => {
							setOpenModal(true);
							setSelectedRow({});
						}}
					>
						Nuevo Rol
					</Button>

					<TableComponent
						headerFormatters={HEADER_FORMATTERS}
						dataFormatters={DATA_FORMATTERS}
						dependencies={{}}
						columns={columns}
						data={roles}
						onClick={onRowClicked}
						perPage={3}
					/>
					<AccessManagerModal
						form={form}
						open={openModal}
						onCancel={handleModalCancel}
						onOk={handleModalOk}
						onDelete={handleDelete}
						values={selectedRow}
						dependencies={{ role: roles }}
					/>
				</Content>
			</Layout>
		</>
	);
};

export default AccessManager;
