import React, { useEffect } from 'react';
import {
	ApiOutlined,
	FileTextOutlined,
	LockOutlined,
	TabletOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';
import ModalButtons from '../../../../components/buttons/ModalButtons';

const DEFAULT_VALUES = {
	providerType: null,
	publicIp: null,
	username: null,
	password: null,
	routerboard: null,
};

const ProvidersModal = ({ form, values, open, onOk, onCancel, onDelete }) => {
	
	useEffect(() => {
		if (values.id) {
			form.setFieldsValue(values);
		} else {
			form.setFieldsValue(DEFAULT_VALUES);
		}
	}, [form, values]);

	const handleDelete = () => {
		form.resetFields();
		form.setFieldsValue({});
		onDelete(values.id);
	};

	return (
		<>
			<Modal
				title={values.id ? 'Modificar plan' : 'Crear plan'}
				open={open}
				onCancel={onCancel}
				footer={
					<ModalButtons
						values={values}
						form={form}
						onOk={() => {
							const formValues = form.getFieldsValue();
							onOk({
								...formValues,
								id: values.id,
							});
						}}
						onDelete={() =>
							Modal.confirm({
								title: 'Estas seguro que deseas eliminar este proveedor?',
								okText: 'Eliminar',
								cancelText: 'Cancelar',
								onOk: handleDelete,
							})
						}
					/>
				}
			>
				<Form className='password-form' form={form} name='password_form' layout='vertical'>
					<Form.Item
						name='providerType'
						label={values.providerType ? 'Nombre proveedor' : ''}
						rules={[{ required: true, message: 'Proveedor es olbigatorio' }]}
					>
						<Input placeholder='Nombre proveedor' prefix={<FileTextOutlined />} />
					</Form.Item>

					<Form.Item
						name='publicIp'
						label={values.publicIp ? 'Ip de winbox' : ''}
						rules={[{ required: true, message: 'Velocidad del plan es obligatorio' }]}
					>
						<Input placeholder='Ip de winbox' prefix={<ApiOutlined />} />
					</Form.Item>

					<Form.Item
						name='username'
						label={values.username ? 'Usuario' : ''}
						rules={[{ required: true, message: 'El valor del plan es obligatorio' }]}
					>
						<Input placeholder='Usuario' prefix={<UserOutlined />} />
					</Form.Item>

					<Form.Item
						name='password'
						label={values.password ? 'Contraseña' : ''}
						rules={[{ required: true, message: 'El valor del plan es obligatorio' }]}
					>
						<Input.Password
							placeholder='Contraseña'
							prefix={<LockOutlined />}
							visibilityToggle={false}
						/>
					</Form.Item>

					<Form.Item
						name='routerboard'
						label={values.routerboard ? 'Referencia routerboard' : ''}
						rules={[{ required: true, message: 'El valor del plan es obligatorio' }]}
					>
						<Input
							placeholder='Referencia routerboard'
							prefix={<TabletOutlined />}
							visibilityToggle={false}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default ProvidersModal;
