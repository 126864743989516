import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/es_ES';
import 'dayjs/locale/es';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<ConfigProvider locale={locale}>
			<Provider store={store}>
				<App />
			</Provider>
		</ConfigProvider>
	</React.StrictMode>,
);
